import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./Route/PrivateRoute";
import ProtectedRoute from "./Route/protectedRoute";
import "./index.css";
import "./app.css";

// import "./index.scss";
import "./custom.css";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import Filter from "./views/pages/filter/Filter";

const loading = (
  <div className="flexbox">
    <div>
      <div className="mesh-loader">
        <div className="set-one">
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
        <div className="set-two">
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const LoginMobile = React.lazy(() =>
  import("./views/pages/login/LoginMobile.js"),
);
const Profile = React.lazy(() => import("./views/pages/profile/Profile"));
const LoginEmail = React.lazy(() =>
  import("./views/pages/login/LoginEmail.js"),
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/login/ForgotPassword.js"),
);
const Home = React.lazy(() => import("./views/pages/home/Home"));
const HomeNew = React.lazy(() => import("./views/pages/home/HomeNew"));
const Contact = React.lazy(() => import("./views/pages/contact/Contact"));
const About = React.lazy(() => import("./views/pages/about/About"));
const NewsFeed = React.lazy(() => import("./views/pages/newsFeed/NewsFeed"));
const Term = React.lazy(() => import("./views/pages/Policy/Policy"));
const Terms = React.lazy(() => import("./views/pages/Terms/Terms"));
const Help = React.lazy(() => import("./views/pages/Help/Help"));

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Chat = React.lazy(() => import("./views/pages/chat/Chat"));
const Verify = React.lazy(() => import("./views/pages/verification/verify"));
const ErrorPage = React.lazy(() => import("./views/pages/verification/error"));
const ExperiencePage = React.lazy(() =>
  import("./views/pages/experiencePage/ExperiencePage.js"),
);
const Experience = React.lazy(() =>
  import("./views/pages/experience/Experience.js"),
);
const Home_2 = React.lazy(() => import("./views/pages/home/Home_2.js"));
const User = React.lazy(() =>
  import("./views/pages/userProfile/UserProfile.js"),
);
class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Suspense fallback={loading}>
            <ToastContainer />
            <Routes>
              {/* <Route exact path="/home" name="Home Page" element={<Home />} /> */}
              <Route
                exact
                path="/home_old"
                name="new Home Page"
                element={<HomeNew />}
              />
              <Route exact path="/home" name="new Home" element={<Home_2 />} />
              <Route
                exact
                path="/login-mobile"
                name="login"
                element={<LoginMobile />}
              />
              <Route
                exact
                path="/login-verify"
                name="login"
                element={<LoginEmail />}
              />
              <Route
                exact
                path="/login-email"
                name="loginEmail"
                element={<LoginEmail />}
              />
              <Route
                exact
                path="/forgot-password"
                name="ForgotEmail"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/create-password/:id"
                name="ForgotEmail"
                element={<ForgotPassword />}
              />
                <Route
                exact
                path="/payment-verify"
                name="PaymentVerify"
                element={<Verify />}
              />
              <Route
                exact
                path="/payment-failed"
                name="PaymentFailed"
                element={<ErrorPage />}
              />
              <Route
                exact
                path="/login"
                name="Login"
                element={
                  <ProtectedRoute>
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/contact_us"
                name="Login"
                element={<Contact />}
              />
              <Route exact path="/about_us" name="Login" element={<About />} />
              <Route
                exact
                path="/news_feed"
                name="Login"
                element={<NewsFeed />}
              />

              <Route
                exact
                path="/ExperiencePage/:slug"
                element={<ExperiencePage />}
              />
              <Route exact path="/experience" element={<Experience />} />
              <Route exact path="/user" element={<User />} />
              <Route exact path="/privacy" element={<Term />} />
              <Route exact path="/terms" element={<Terms />} />
              <Route exact path="/help" element={<Help />} />
              <Route exact path="/filter" element={<Filter />} />
              {/* <Route exact path="/bankDetail" element={<BankDetail1 />} /> */}
              <Route exact path="/profile" name="Login" element={<Profile />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                path="*"
                name="Home"
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              />
              <Route path="chat" name="Chat" element={<Chat />} />
              <Route
                exact
                path="/create-password/:id"
                name="ForgotEmail"
                element={<ForgotPassword />}
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
    );
  }
}

export default App;
