import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Navigation from "./navigation.js";
import imagePlaceholder from "../../../assets/elala/placeholder-image.jpg";
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
`;

const Slide = styled.div`
  width: 100%;
  display: flex;
  transition: transform 0.6s ease-in-out;
  transform: ${(props) => `translateX(${props.xPosition}px)`}; // (*)
  img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
    mix-blend-mode: color-burn;
  }
`;

function Carousel({
  images,
  setWidth,
  xPosition,
  handleClickPrev,
  handleClicknext,
}) {
  const slideRef = useRef();
  useEffect(() => {
    if (slideRef.current) {
      const width = slideRef.current.clientWidth;
      setWidth(width);
    }
  }, [setWidth]);

  return (
    <Wrapper>
      <Slide xPosition={xPosition} ref={slideRef}>
        {images?.map((item, key) => (
          <img
            src={
              `https://admin.kentgo.com/${item?.thumb}` || imagePlaceholder
            }
            className="mr-3 ml-3"
            alt={imagePlaceholder}
            key={key}
          />
        ))}
      </Slide>
      {images.length > 1 && (
        <Navigation
          handleClickPrev={handleClickPrev}
          handleClicknext={handleClicknext}
        />
      )}
    </Wrapper>
  );
}
export default Carousel;
