const API_CONFIG = "https://admin.kentgo.com/api";
// const API_CONFIG = "https://elala.matoresell.com/api";
// const API_Local = "http://localhost:5000/api";
const IMAGE_URL = "https://admin.kentgo.com/";
// const IMAGE_URL = "https://elala.matoresell.com/";
const PAGE_HOME_URL = "https://kentgo.com/home";
const PAGE_PROFILE_URL = "https://kentgo.com/profile";

// const API_CONFIG = "http://localhost:8000/api";
// const API_Local = "http://localhost:5000/api";
// const IMAGE_URL = "http://localhost:8000/";
// const PAGE_HOME_URL = "http://localhost:3000/home";
// const PAGE_PROFILE_URL = "http://localhost:3000/profile";


const utils = {
  hostURL: (url) => {
    return `${API_CONFIG}${url}`;
  },
  imageURL: (url) => {
    return `${IMAGE_URL}${url}`;
  },
  LocalURL: (url) => {
    return `${API_Local}${url}`;
  },
  LocalHomePage: () => {
    return `${PAGE_HOME_URL}`
  },
  LocalProfilePage: () => {
    return PAGE_PROFILE_URL
  }
};

export default utils;
