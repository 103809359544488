import { toast } from "react-toastify";

export const setInToStorage = async (data) => {
  // Object.keys(data).forEach(item => {
  await localStorage.setItem("token", data.token);
  // })
};
export const setUserDetails = async (data) => {
  await localStorage.setItem("userDetails", data);
};
export const setUsersDataStorage = (data) => {
  // Object.keys(data).forEach(item => {
  return data ? localStorage.setItem("user_data", JSON.stringify(data)) : {};
  // })
};
export const getUserData = () => {
  // Object.keys(data).forEach(item => {
  return localStorage.getItem("user_data") == undefined ?  {} : JSON.parse(localStorage.getItem("user_data"));
  // })
};

export const getFromStorage = (key) => {
  return localStorage.getItem(key);
};
export const getUserDetails = (key) => {
  return localStorage.getItem(key);
};

export const toastError = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastSuccess = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

export const setIsProfileCreated = async (flag) => {
  await localStorage.setItem("isProfileCreated", flag);
};

export const getIsProfileCreated = async () => {
  const flag = localStorage.getItem("isProfileCreated");
  return flag
};
