import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import imagePlaceholder from "../../assets/elala/placeholder-image.jpg";
import noImg from "../../assets/elala/NOdata.png";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// import "swiper/scss";
// import "swiper/css/navigation";
// import "swiper/scss/pagination";
import Carousel from "./components/carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CaSwiped from "react-bootstrap/Carousel";
function CardBox({ data, setData, index_, previousLength }) {
  let navigate = useNavigate();
  const swiperRef = useRef();
  const [index1, setIndex1] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex1(selectedIndex);
  };
  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [xPosition, setXPosition] = useState(0);

  const images = data?.image;

  const handleClickPrev = () => {
    if (index === 0) return;
    setIndex(index - 1);
    setXPosition(xPosition + width);
  };
  const handleClicknext = () => {
    if (index === images.length - 1) {
      setIndex(0);
      setXPosition(0);
    } else {
      setIndex(index + 1);
      setXPosition(xPosition - width);
    }
  };
  const handleClick = () => {
    setIndex(index);
  };
  return (
    <div className="owl-item" style={{ cursor: "pointer" }}>
      <div className="expersions_img">
        {setData  ? (
          <>
            <Skeleton height={230} width={230} borderRadius={15} />
          </>
        ) : (
          <>
            {typeof data?.image !== "string" ? (
              data?.image?.length > 0 ? (
                <>
                  <div className="for_slider">
                    <LazyLoadImage
                      onClick={() => {
                        navigate(`/experiencePage/${data?.slug}`, {
                          state: data,
                        });
                      }}
                      src={
                        `https://admin.kentgo.com/${data?.image[0].thumb}` ||
                        imagePlaceholder
                      }
                      alt="Image Alt"
                      effect="blur"
                    />

                    {/* <CaSwiped
                      activeIndex={index1}
                      onSelect={handleSelect}
                      indicators={false}
                      interval={null}
                    >
                      {data?.image?.map((item, key) => (
                        <CaSwiped.Item key={key}>
                          <LazyLoadImage
                            onClick={() => {
                              navigate(`/experiencePage/${data?.slug}`, {
                                state: data,
                              });
                            }}
                            src={
                              `https://admin.kentgo.com/${item?.thumb}` ||
                              imagePlaceholder
                            }
                            alt="Image Alt"
                            effect="blur"
                          />
                        </CaSwiped.Item>
                      ))}
                    </CaSwiped> */}
                    {/* <Carousel
                      images={images}
                      setWidth={setWidth}
                      xPosition={xPosition}
                      handleClickPrev={handleClickPrev}
                      handleClicknext={handleClicknext}
                      handleClick={handleClick}
                    /> */}
                    {/* <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                      loop={true}
                      navigation
                       slidesPerView={1}
                      spaceBetween={30}
                    >
                      {data?.image?.map((item, key) => (
                        <SwiperSlide key={key}>
                          <img
                            src={
                              `https://admin.kentgo.com/${item?.thumb}` ||
                              imagePlaceholder
                            }
                            alt=""
                            onClick={() => {
                              navigate(`/experiencePage/${data?.slug}`, {
                                state: data,
                              });
                            }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper> */}
                  </div>
                </>
              ) : (
                <img
                  src={imagePlaceholder}
                  alt=""
                  onClick={() => {
                    navigate(`/experiencePage/${data?.slug}`, {
                      state: data,
                    });
                  }}
                />
              )
            ) : (
              <img src={data?.image || imagePlaceholder} alt="" />
            )}
          </>
        )}
        {data?.rating > 0 ? (
          <div className="experiences-star-white">
            <>
              <FontAwesomeIcon icon={faStar} />
              <span className="ms-1">{data?.rating}</span>
            </>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <div className="experiences_box mt-2">
          {setData && (previousLength ? index_ > previousLength : true) ? (
            <Skeleton
              className="experiences_heading newHeading mb-2"
              height={16.99}
            />
          ) : (
            <div
              className="experiences_heading mb-1"
              onClick={() => {
                navigate(`/experiencePage/${data?.slug}`, {
                  state: data,
                });
              }}
            >
              {data?.title}
            </div>
          )}
          {setData && (previousLength ? index_ > previousLength : true) ? (
            <Skeleton
              className="experiences_location newHeading mb-2"
              height={15.99}
            />
          ) : (
            <div
              className="experiences_location mb-2"
              onClick={() => {
                navigate(`/experiencePage/${data?.slug}`, {
                  state: data,
                });
              }}
            >
              {data?.location}
            </div>
          )}

          {setData && (previousLength ? index_ > previousLength : true) ? (
            <Skeleton className="price newHeading" height={19.09} />
          ) : (
            <>
              <div
                className="experiences_price"
                onClick={() => {
                  navigate(`/experiencePage/${data?.slug}`, {
                    state: data,
                  });
                }}
              >
                <span className="price">₹ {data?.individual_rate}/Person</span>
              </div>
            </>
          )}
          <span
            className="person_part"
            onClick={() => {
              navigate(`/experiencePage/${data?.slug}`, {
                state: data,
              });
            }}
          >
            {data?.person}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CardBox;
