import {
  faChevronLeft,
  faChevronRight,
  faLeftLong,
  faRightLeft,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import "./navigation.scss";
const Wrapper = styled.div`
  display: block;
  padding: 1rem;
  width: 300px;
  background-color: #000;
  opacity: 0.7;
`;

const List = styled.ul`
  list-style: none;
`;

const Button = styled.div`
  position: absolute;
  top: 50%;
  z-index: 10;
  background: #ffffffc7;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
  font-size: 15px;
  transform: translateY(-50%);
  left: ${(props) => props.side === "prev" && 5}px;
  right: ${(props) => props.side === "next" && 5}px;
`;
function Navigation({ handleClickPrev, handleClicknext }) {
  return (
    <>
      <button
        side="prev"
        className="next-btn-image-slide-left"
        onClick={handleClickPrev}
      >
        <div className="arrow-right"></div>
      </button>
      <button
        side="prev"
        className="next-btn-image-slide"
        onClick={handleClicknext}
      >
        <div className="arrow-left"></div>
      </button>
    </>
  );
}
export default Navigation;
