import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

const PrivateRoute = ({ children }) => {
  const auth = useAuth();

  return auth ? children : <Navigate to="/home" />;
};

export default PrivateRoute;
