import React, { useEffect, useState } from "react";
import socketio from "socket.io-client";
import ChatStore from "./SupportChatStore";
const SocketUrl = process.env.REACT_APP_SOCKET_URL;
export const SocketContext = React.createContext();

const SocketContextProvider = ({ children }) => {
    const useDetail = JSON.parse(localStorage.getItem("userDetails"));
    const appendChatLogs = ChatStore(state => state.appendChatLogs);
    const [chatClient, setChatClient] = useState(null);
    const [getUseDetail, setUseDetail] = useState(null);
    const [connected, setConnected] = useState(false);
    const [pathName, setPathName] = useState({});
    const [lastId, setLastId] = useState(null)
    useEffect(() => {
        if(useDetail?.id){

            setUseDetail(useDetail.id);
        }

    }, [useDetail]);

    useEffect(() => {
        if (getUseDetail) {
            const initChat = () => {
                const client = socketio("wss://kentgo.com/", {
                    query: {
                        user_id: getUseDetail,
                    },
                    transports: ["websocket"],
                    upgrade: true,
                    reconnection: false,
                    autoConnect: false,
                    forceNew: true,
                });
                if (!client.connected) {
                    client.connect();
                }

                setChatClient(client);
                client.on('connect', function () {
                    setTimeout(() => {
                        setConnected(true);
                    }, 600);
                });
            };
            initChat();
        return () => chatClient?.disconnect();

        }


    }, [getUseDetail]);
    useEffect(() => {
        if (connected) {
            chatClient.on("supportMessage", function (data) {
                console.log("on event");
                // const currentObj = {
                //     chatMessageType: data.msgType,
                //     chatLogId: data._id,
                //     chatLogText: data.message,
                //     ChatSenderType: data.sendBy,
                //     ChatDisputeId: data.feedbackId,
                //     chatLogTimeStamp: new Date(data.createdAt),
                //     chatReadStatus: 1,
                // };
                appendChatLogs(data);
            });
        }
        return () => {
            if (connected) {
                chatClient.off("supportMessage");
            }
        };
    }, [connected])
    return (
        <SocketContext.Provider value={{ connected, setConnected, chatClient}}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketContextProvider;
