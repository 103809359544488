import axios from "axios";
import utils from "./index";
import { getFromStorage, getUserDetails } from "./common";
let token = getFromStorage("token");
let UserDetails = getUserDetails("userDetails");

const config = () => ({
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});
const CustomToken = (data) => ({
  headers: {
    Authorization: token ? `Bearer ${data}` : "",
    "Content-Type": "multipart/form-data",
  },
});

const configFormData = () => ({
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
    "Content-Type": "multipart/form-data",
  },
});

// ***************************************************login*************************************************
export async function login(data) {
  // try {
  const url = utils.hostURL(`/login`);
  const res = await axios.post(url, data, config());
  if (res.status === 200) {
    token = res.data.data.token;
    UserDetails = res.data.data;
  }
  return res;
  // return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function emailsend(data) {
  // try {
  const url = utils.hostURL(`/emailsend`);
  const res = await axios.post(url, data, config());
  if (res.status === 200) {
    
  }
  return res;
  // return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
// ******************************************************* POST api***********************************************

export async function forgotPassword(data) {
  const url = utils.hostURL(`/resetpassword`);
  return await axios.post(url, data, config());
}
export async function addExperienceType(data, dispatch) {
  try {
    if (dispatch) {
      dispatch({ type: "loading", loading: true });
    }
    const url = utils.hostURL(`/addExperienceType`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    if (dispatch) {
      dispatch({ type: "loading", loading: false });
    }
  }
}

export async function addExperienceLocation(data, dispatch) {
  try {
    if (dispatch) {
      dispatch({ type: "loading", loading: true });
    }
    const url = utils.hostURL(`/addExperienceLocation`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    if (dispatch) {
      dispatch({ type: "loading", loading: false });
    }
  }
}

// massages screen Api ==================================================>

export async function getAllChat(data, dispatch) {
  try {
    if (dispatch) {
      dispatch({ type: "loading", loading: true });
    }
    const url = utils.hostURL(`/get-all-chat/${data}`);

    return await axios.get(url, config());
  } catch (error) {
  } finally {
    if (dispatch) {
      dispatch({ type: "loading", loading: false });
    }
  }

  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function personalChat(sand_id, receiver_id) {
  // try {
  const url = utils.hostURL(`/personal-chat/${sand_id}/${receiver_id}`);

  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function unreadMsgCount(data) {
  // try {
  const url = utils.hostURL(`/unread-msg-count`);
  return await axios.get(url, data, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function getAllUnreadMsgCount(data) {
  // try {
  const url = utils.hostURL(`/get_all_unread_msg_count`);
  return await axios.get(url, data, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function createChat(data) {
  // try {
  const url = utils.hostURL(`/create-chat`);
  return await axios.post(url, data, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function forgotPasswordsEmail(data) {
  // try {
  const url = utils.hostURL(`/forgetpassword`);
  return await axios.post(url, data, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}

export async function addExperienceCategory(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceCategory`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceDetails(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceDetails`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceMedia(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceMedia`);
    let formData = new FormData();
    data?.images?.forEach((images) => {
      formData.append("images[]", images);
    });
    formData.append("video", data?.video);
    formData.append("image", data?.image);
    formData.append("experience_id", data?.experience_id);
    return await axios.post(url, formData, configFormData());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceAgeGroup(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceAgeGroup`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function profileEdit(data, token) {
  let formData = new FormData();
  formData.append("dob", data?.dob);
  formData.append("email", data?.email);
  formData.append("gender", data?.gender);
  formData.append("bio", data?.bio);
  formData.append("mobile_no", data?.mobile_no);
  formData.append("name", data?.name);
  formData.append("profile_pic", data?.profile_pic);
  formData.append("is_private", data?.is_private);
  const url = utils.hostURL(`/editProfile`);
  return await axios.post(url, formData, CustomToken(token));
}
export async function verificationOtp(data) {
  const url = utils.hostURL(`/verify_otp`);
  return await axios.post(url, data, config());
}
export async function addExperienceBriandItem(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceBrindItem`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceProvideItem(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceProvideItem`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceMeetLocation(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceMeetLocation`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceMaxGroupSize(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceMaxGroupSize`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceScheduleTime(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceScheduleTime`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperiencePrice(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperiencePrice`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addDiscountGroup(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addDiscountGroup`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addExperienceCancelletionPolicy(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceCancelletionPolicy`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addLetAndLong(data) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${data?.data_id}&key=${data?.data_key}`;
  return await axios.post(url);
}
export async function grtCity(data) {
  const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=surat&callback=initMap`;
  return await axios.get(url);
}
export async function mobilDataByCountry() {
  const url = `https://gist.githubusercontent.com/kcak11/4a2f22fb8422342b3b3daa7a1965f4e4/raw/3d54c1a6869e2bf35f729881ef85af3f22c74fad/countries.json`;
  return await axios.get(url);
}
export async function addExperienceCategoryAttribute(data, dispatch) {
  try {
    dispatch({ type: "loading", loading: true });
    const url = utils.hostURL(`/addExperienceCategoryAttribute`);
    return await axios.post(url, data, config());
  } catch (error) {
  } finally {
    dispatch({ type: "loading", loading: false });
  }
}
export async function addCheckorderslot(data) {
  const url = utils.hostURL(`/checkorderslot`);
  return await axios.post(url, data, config());
}
export async function addOrder(data) {
  const url = utils.hostURL(`/payment_initiate`);
  return await axios.post(url, data, config());
}
export async function phonePeVerification(data) {
  const url = utils.hostURL(`/phonepe-response`);
  return await axios.post(url, data, config());
}
export async function removeImage(data) {
  const url = utils.hostURL(`/removeMediaExperience?media_id=${data}`);
  return await axios.get(url, config());
}

export async function orderData(data) {
  const url = utils.hostURL(`/getMyOrders`);
  return await axios.post(url, data, config());
}
export async function hostOrders(data) {
  const url = utils.hostURL(`/getHostOrders`);
  return await axios.post(url, data, config());
}

export async function wishlistData(data) {
  const url = utils.hostURL(`/wishlistitem_list?user_id=${data}`);
  return await axios.get(url, config());
}

export async function updateWishlist(data) {
  const url = utils.hostURL(`/update_wishlist`);
  return await axios.post(url, data, config());
}

export async function contactUs(data) {
  const url = utils.hostURL(`/contact`);
  return await axios.post(url, data, config());
}
export async function availablePrivateGroupDate(data) {
  const url = utils.hostURL(`/availableprivategroupdate`);
  return await axios.post(url, data, config());
}

export async function myPost(data) {
  const url = utils.hostURL(`/myPost`);
  return await axios.post(url, data, config());
}
export async function allPost(data) {
  const url = utils.hostURL(`/allPost`);
  return await axios.post(url, data, config());
}
export async function likePost(data) {
  const url = utils.hostURL(`/likePost`);
  return await axios.post(url, data, config());
}

export async function likePostUser(data) {
  const url = utils.hostURL(`/likePostUser`);
  return await axios.post(url, data, config());
}
export async function commantPostUser(data) {
  const url = utils.hostURL(`/commantPostUser`);
  return await axios.post(url, data, config());
}
export async function commantPost(data) {
  const url = utils.hostURL(`/commantPost`);
  return await axios.post(url, data, config());
}
export async function deletePost(data) {
  const url = utils.hostURL(`/deletePost`);
  return await axios.post(url, data, config());
}
export async function pastpayment(data) {
  const url = utils.hostURL(`/pastpayment`);
  return await axios.post(url, data, config());
}
export async function upcomingpayment(data) {
  const url = utils.hostURL(`/upcomingpayment`);
  return await axios.post(url, data, config());
}
export async function pastupComingPayment(data) {
  const url = utils.hostURL(`/pastupcomingpayment`);
  return await axios.post(url, data, config());
}
export async function nextlastpayment(data) {
  const url = utils.hostURL(`/nextlastpayment`);
  return await axios.post(url, data, config());
}
export async function getFollower(data) {
  const url = utils.hostURL(`/getFollower`);
  return await axios.post(url, data, config());
}
export async function follow_request(data) {
  const url = utils.hostURL(`/follow_request`);
  return await axios.post(url, data, config());
}
export async function getRandomUsers(data) {
  const url = utils.hostURL(`/getRandomUsers`);
  return await axios.post(url, data, config());
}
export async function follow(data) {
  const url = utils.hostURL(`/follow`);
  return await axios.post(url, data, config());
}
export async function unfollow(data) {
  const url = utils.hostURL(`/unfollow`);
  return await axios.post(url, data, config());
}
export async function viewProfile(data) {
  const url = utils.hostURL(`/viewProfile`);
  return await axios.post(url, data, config());
}
export async function getCountry(data) {
  const url = utils.hostURL(`/getCountry`);
  return await axios.post(url, data, config());
}
export async function getState(data) {
  const url = utils.hostURL(`/getState`);
  return await axios.post(url, data, config());
}
export async function getCity(data) {
  const url = utils.hostURL(`/getCity`);
  return await axios.post(url, data, config());
}
export async function getFilterExperiences(data) {
  const url = utils.hostURL(`/getFilterExperiences`);
  return await axios.post(url, data, config());
}
export async function getMyExperienceReview(data) {
  const url = utils.hostURL(`/getMyExperienceReview`);
  return await axios.post(url, data, config());
}

// **************************************************GET api************************************************

export async function getAllExperience(data) {
  const url = utils.hostURL(
    `/getHomeExperiences?page=${data?.page}&limit=${data?.limit}`,
  );
  return await axios.get(url, config());
}

export async function removeExperience() {
  // try {
  const url = utils.hostURL(`/removeExperience?experience_id=2`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function getExperience(data) {
  // try {
  const url = utils.hostURL(
    `/getExperience?experience_id=${data?.experience_id}`,
  );
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function getExperiencesData(data) {
  // try {
  const url = utils.hostURL(`/getExperiences?user_id=${data?.user_id}`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}

export async function getOrderCalender(data) {
  // try {
  const url = utils.hostURL(`/getOrderCalender/${data}`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function getCityData() {
  // try {
  const url = utils.hostURL(`/city/sur`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}

export async function getOtherList() {
  // try {
  const url = utils.hostURL(`/otherlist`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}

export async function approvalListedit(data, value) {
  // try {
  const url = utils.hostURL(`/users/updateApprovalUser/${data}`);
  return await axios.put(url, value, configFormData());

  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function UserPendingList() {
  // try {
  const url = utils.hostURL(`/users/pandingRequestUser`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function approvalList() {
  // try {
  const url = utils.hostURL(`/users/approvalList`);
  const res = await axios.get(url, config());
  return { success: true, data: res.data };
  // }
  //  catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}

export async function notapprovalList() {
  // try {
  const url = utils.hostURL(`/users/pandingRequestUser`);
  const res = await axios.get(url, config());
  return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function requiredapprovalLise(data) {
  // try {
  const url = utils.hostURL(`/users/registrationApproval`);
  const res = await axios.put(url, data, config());
  return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function getGallary() {
  // try {
  const url = utils.hostURL(`/gallery`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}
export async function getForgotList() {
  // try {
  const url = utils.hostURL(`/passwordRequest/`);
  return await axios.get(url, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}

export async function getProduct() {
  // try {
  const url = utils.hostURL(`/product`);
  return await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: "something went wrong" };
  // }
}

const userId = JSON.parse(UserDetails)?.id;

export async function getExperienceData(data) {
  const url = utils.hostURL(
    `/experienceDetails/${data}${userId ? `?user_id=${userId}` : ""}`,
  );
  return await axios.get(url, config());
}
export async function getReviewExperiences(data) {
  const url = utils.hostURL(`/getReviewExperiences/${data}`);
  return await axios.get(url, config());
}
export async function getRemoveExperience(data) {
  const url = utils.hostURL(`/removeExperience?experience_id=${data}`);
  return await axios.get(url, config());
}
export async function getRelatedExperiences(data) {
  const url = utils.hostURL(`/getRelatedExperiences/${data}`);
  return await axios.get(url, config());
}
export async function getOrderDetails(data) {
  const url = utils.hostURL(`/getOrderDetails/${data}`);
  return await axios.get(url, config());
}
export async function addReview(data) {
  const url = utils.hostURL(`/add_review`);
  return await axios.post(url, data, config());
}
export async function addEditBank(data) {
  const url = utils.hostURL(`/addEditBank`);
  return await axios.post(url, data, config());
}
export async function getBank() {
  const url = utils.hostURL(`/getBank`);
  return await axios.get(url, config());
}
export async function getAvailableTime(data, day) {
  const url = utils.hostURL(`/getAvailableTimeExperiences/${data}/${day}`);
  return await axios.get(url, config());
}
export async function requestToBook() {
  const url = utils.hostURL(`/requestToBook`);
  return await axios.get(url, config());
}
export async function settings() {
  const url = utils.hostURL(`/settings`);
  return await axios.get(url, config());
}
export async function getInfo() {
  const url = utils.hostURL(`/infopage`);
  return await axios.get(url);
}
export async function getTeamMember() {
  const url = utils.hostURL(`/getTeamMember`);
  return await axios.get(url, config());
}
export async function getTestimonial() {
  const url = utils.hostURL(`/getTestimonial`);
  return await axios.get(url, config());
}
export async function getFaq() {
  const url = utils.hostURL(`/getFaq`);
  return await axios.get(url, config());
}
export async function paymentHistory() {
  const url = utils.hostURL(`/paymentHistory`);
  return await axios.get(url, config());
}

export async function getCount() {
  const url = utils.hostURL(`/getCount`);
  return await axios.get(url, config());
}

export async function addGallary(data) {
  // try {
  const url = utils.hostURL(`/gallery`);
  return await axios.post(url, data, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function getCityFullData(data) {
  // try {
  const url = "";
  return await axios.post(url, data, config());
  //   return { success: true, data: res.data };
  // } catch (e) {
  //   return { success: false, msg: e.response.data.message };
  // }
}
export async function getUserListForPopup(search) {
  const url = utils.hostURL(`/getUsers?search=${search}`);
  return await axios.get(url, config());
}

export async function createNewsFeedForm(data) {
  let formData = new FormData();
  data?.media?.forEach((images) => {
    formData.append("media[]", images);
  });
  formData.append("description", data?.description);
  formData.append("host_tag", data?.host_tag);
  formData.append("is_private", data?.is_private);
  formData.append("post_id", data?.post_id);
  formData.append("tags", data?.tags);
  const url = utils.hostURL(`/createPost`);
  return await axios.post(url, formData, configFormData());
}
