import React, { useEffect, useRef, useState } from "react";
import Footer from "src/components/footer";
import CardBox from "src/components/Slider";
import {
  getFilterExperiences,
  getOtherList,
  getCity,
  getCountry,
  getState,
} from "src/utils/_data";
import HomeNav from "src/components/header/HomeNav";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faAngleDown, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
  Keyboard,
} from "swiper";
import RcPagination from 'rc-pagination';
import "rc-pagination/assets/index.css"
import "swiper/css";
import "swiper/css/navigation";
import { Link, useLocation } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import MultiRangeSlider from "./MultiRangeSlider";
import "./multiRangeSlider.css";

function Filter() {
  const [filterExperiences, setFilterExperiences] = useState([]); // getFilterExperiences api
  const [filterExperiencesLength, setFilterExperiencesLength] = useState(0);
  const [total, setTotal] = useState(5);
  const location = useLocation();
  const [country, setCountry] = useState(location?.state?.country);
  const [state, setState] = useState(location?.state?.state);
  const [citys, setCity] = useState(location?.state?.city);
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [activity, setActivity] = useState([]);
  const [getCategoryids, setCategory] = useState();
  const [activityStatusFilter, setActivityStatusFilter] = useState([]);
  const swiperRef = useRef();
  const [filterList, setFilterList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [max, setMax] = useState();
  const [min, setMin] = useState();
  const [languageOffered, setLanguageOffered] = useState();
  const [activityType, setActivityType] = useState();
  const [weekendType, setWeekendType] = useState();
  const [sorting, setSorting] = useState("desc");
  const [rating, setRating] = useState();
  const [pageLength, setPageLength] = useState(1);
  const [pageLimit, setPageLimit] = useState(0);
  const [data, setData] = useState({
    page: 1,
    limit: 0,
    language: null,
    days: null,
    activity_type: null,
    city: null,
    country: null,
    state: null,
    min_price: null,
    max_price: null,
    categories: null,
  });

  const [history, setHistory] = useState({
    page: 1,
    limit: 0,
    language: null,
    days: null,
    activity_type: null,
    city: null,
    country: null,
    state: null,
    min_price: null,
    max_price: null,
    categories: null,
  });
  const [otherListData, setOtherListData] = useState([]); // otherlist
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Filter";
    getCountry().then((res) => {
      if (res?.data?.success) {
        setCountry(res?.data?.data);
      }
    });
    getOtherList().then((res) => {
      if (res?.data?.success) {
        setOtherListData(res?.data?.data);
        setFilterList(res?.data?.data?.categories);
      }
    });
  }, []);

  useEffect(() => {
    setActivity({
      ...activity,
      activity: activityData,
      language: otherListData?.languages,
      weekdays: weekdays,
    });
  }, [otherListData]);

  const [width, setWidth] = useState(0);
  const [scroll, setScrollY] = useState(400);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  if (width >= 1198) {
    if (pageLimit !== 18) {
      // setData({ ...data, limit: 18 });
      setPageLimit(18);
    }
  } else if (width > 1000) {
    if (pageLimit !== 12) {
      // setData({ ...data, limit: 12 });
      setPageLimit(12);
    }
  } else if (width > 770) {
    if (pageLimit !== 9) {
      // setData({ ...data, limit: 9 });
      // setData((prevState) => ({
      //   ...prevState,
      //   ['limit']: 9,
      // }));

      setPageLimit(9);

    }
  } else if (width > 446) {
    if (pageLimit !== 6) {
      // setData({ ...data, limit: 6 });
      setPageLimit(6);

    }
  } else if (width > 203) {
    if (pageLimit !== 3) {
      // setData({ ...data, limit: 3 });
      setPageLimit(3)
    }
  }

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > scroll) {
        setScrollY(scroll + 800);
        // setData({ ...data, page: data.page + 1 });
        // setPageLength(pageLength+1)
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  const [loading, setLoading] = useState(true);
  const handleFillter = (data) => {
    // if (history.limit !== data.limit || (history.page !== data.page &&data.limit !== 0 &&data.page !== 0 &&!history?.stop)) {
    const fillterData = {
      "country": countryId,
      "state": stateId,
      "city": cityId,
      "categories": getCategoryids,
      "max_price": max,
      "min_price": min,
      "language": languageOffered,
      "activity_type": activityType,
      "days": weekendType,
      "sort_order": sorting,
      "rating": rating,
      "page":pageLength
      // "limit": pageLimit
    }
    setLoading(true);
    setFilterExperiencesLength(filterExperiences.length);
    getFilterExperiences(fillterData)
      .then((result) => {
        if (result.data.success === true) {
          setHistory(data);
          if (result?.data?.data?.experiences.length > 0) {
            setFilterExperiences([])
            setFilterExperiences(result?.data?.data?.experiences,);
            setTotal(result?.data?.data?.total);
          } else {
            setHistory((prevState) => ({
              ...prevState,
              ['stop']: true,
            }));
            // setHistory({ ...history, stop: true });
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      });
    // }
  }
  useEffect(() => {
    handleFillter()
  }, [pageLimit]);

  useEffect(() => {
    handleFillter()
  }, [pageLength]);

  useEffect(() => {
    if (countryId != undefined) {
      getState({ country_id: countryId }).then((res) => {
        if (res?.data?.success) {
          setState(res?.data?.data);
        }
      });
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId != undefined) {
      getCity({ state_id: stateId }).then((res) => {
        if (res?.data?.success) {
          setCity(res?.data?.data);
        }
      });
    }
  }, [stateId]);

  useEffect(() => {
    const selectedCountryId = location?.state?.countryId?.split(",") || [];
    const selectedStateId = location?.state?.stateId?.split(",") || [];
    const selectedCityId = location?.state?.cityId?.split(",") || [];
    var idArr = [];
    const countryIds = country?.filter((item) => {
      if (selectedCountryId?.includes(`${item?.id}`)) {
        let itemId = item?.id;
        idArr.push(itemId);
        let newIds = idArr.toString();
        setCountryId(newIds);
        return item;
      }
    });

    var idArr2 = [];
    const stateIds = state?.filter((item) => {
      if (selectedStateId?.includes(`${item?.id}`)) {
        let itemId = item?.id;
        idArr2.push(itemId);
        let newIds = idArr2.toString();
        setStateId(newIds);
        return item;
      }
    });

    var idArr3 = [];
    const cityIds = citys?.filter((item) => {
      if (selectedCityId?.includes(`${item?.id}`)) {
        let itemId = item?.id;
        idArr3.push(itemId);
        let newIds = idArr3.toString();
        setCityId(newIds);
        return item;
      }
    });

    setSelectedValue({
      ...selectedValue,
      countryIds: countryIds,
      stateIds: stateIds,
      cityIds: cityIds,
    });

  }, [
    location?.state?.countryId,
    location?.state?.stateId,
    location?.state?.cityId,
  ]);

  useEffect(() => {

    setData((prevState) => ({
      ...prevState,
      country: countryId,
    }));
  }, [countryId])

  useEffect(() => {

    setData((prevState) => ({
      ...prevState,
      state: stateId,
    }));
  }, [stateId])

  useEffect(() => {

    setData((prevState) => ({
      ...prevState,
      city: cityId,
    }));
  }, [cityId])

  const getSelecedId = (selectedData) => {
    var idArr = [];
    selectedData?.e.map((item) => {
      let itemId = item?.id;
      idArr.push(itemId);
      let newIds = idArr.toString();
      if (selectedData?.title == "country") {
        setCountryId(newIds);
      } else if (selectedData?.title == "state") {
        setStateId(newIds);
      } else {
        setCityId(newIds);
      }
    });
  };

  const searchBtn = () => {
    // setFilterExperiences([]);

    // setData((prevState) => ({
    //   ...prevState,
    //   ['city']: cityId,
    //   ['country']: countryId,
    //   ['state']: stateId,
    // }));

    // setHistory((prevState) => ({
    //   ...prevState,
    //   ['city']: cityId,
    //   ['country']: countryId,
    //   ['state']: stateId,
    // }));

    handleFillter()

    // setHistory({
    //   city: cityId,
    //   country: countryId,
    //   state: stateId,
    // });

  };

  const activityData = [
    { id: 1, name: "In Person", isSelected: false },
    { id: 2, name: "Online Onliy", isSelected: false },
  ];

  const weekdays = [
    { id: 111, name: "Monday", isSelected: false },
    { id: 221, name: "Tuesday", isSelected: false },
    { id: 32, name: "Wednesday", isSelected: false },
    { id: 41, name: "Thursday", isSelected: false },
    { id: 52, name: "Friday", isSelected: false },
    { id: 40, name: "Saturday", isSelected: false },
    { id: 14, name: "Sunday", isSelected: false },
  ];

  const onChangeActivity = (id, e) => {
    if (e.target.name === "activity") {
      const data = activity.activity;
      const idx = data.findIndex((obj) => obj.id === id);
      data[idx]["isSelected"] = e.target.checked || false;
      const filterActivityData = activity.activity.filter((e) => e.isSelected);
      if (filterActivityData.length == 0) {
        setActivityType()
      }
      var activityIdArr = [];
      filterActivityData.length > 0 &&
        filterActivityData.map((item) => {
          let itemId = item?.id;
          activityIdArr.push(itemId);
          let newIds = activityIdArr.toString();
          setActivityType(newIds)
          // setFilterExperiences([]);
          // setData({ ...data, activity_type: newIds });
          // setData((prevState) => ({
          //   ...prevState,
          //   ['activity_type']: newIds,
          // }));
          // // setHistory({ ...data, activity_type: newIds });
          // setHistory((prevState) => ({
          //   ...prevState,
          //   ['activity_type']: newIds,
          // }))
        });

      setActivityStatusFilter({
        ...activityStatusFilter,
        activity: filterActivityData,
      });
    } else if (e.target.name === "language") {
      const data = activity.language;
      const idx = data.findIndex((obj) => obj.id === id);
      data[idx]["isSelected"] = e.target.checked || false;
      const filterData = activity.language.filter((e) => e.isSelected);
      if (filterData.length == 0) {
        setLanguageOffered()
      }
      var languageIdArr = [];
      filterData.length > 0 &&
        filterData.map((item) => {
          let itemId = item?.id;
          languageIdArr.push(itemId);
          let newIds = languageIdArr.toString();
          setLanguageOffered(newIds)
          // setFilterExperiences([]);
          // // setData({ ...data, language: newIds });
          // setData((prevState) => ({
          //   ...prevState,
          //   ['language']: newIds,
          // }));
          // // setHistory({ ...data, language: newIds });
          // setData((prevState) => ({
          //   ...prevState,
          //   ['language']: newIds,
          // }));
        });

      setActivityStatusFilter({
        ...activityStatusFilter,
        language: filterData,
      });
    } else if (e.target.name === "weekdays") {
      const data = activity.weekdays;
      const idx = data.findIndex((obj) => obj.id === id);
      data[idx]["isSelected"] = e.target.checked || false;
      const filterData = activity.weekdays.filter((e) => e.isSelected);
      if (filterData.length == 0) {
        setWeekendType()
      }
      var daysIdArr = [];
      filterData.length > 0 &&
        filterData.map((item) => {
          let itemName = item?.name;
          daysIdArr.push(itemName);
          let newIds = daysIdArr.toString();
          setWeekendType(newIds)
          // setFilterExperiences([]);
          // // setData({ ...data, days: newIds });
          // setData((prevState) => ({
          //   ...prevState,
          //   ['days']: newIds,
          // }));
          // // setHistory({ ...data, days: newIds });
          // setHistory((prevState) => ({
          //   ...prevState,
          //   ['days']: newIds,
          // }));
        });
      setActivityStatusFilter({
        ...activityStatusFilter,
        weekdays: filterData,
      });
    }
  };

  const handleChange = (min, max) => {
    if (min > 0 || max < 1000) {
      setMax(max)
      setMin(min)
      // console.log(`min = ${min}, max = ${max}`);
      // setFilterExperiences([]);
      // setData({ ...data, min_price: min, max_price: max });
      // setData((prevState) => ({
      //   ...prevState,
      //   ['min_price']: min,
      //   ['max_price']: max,
      // }));
      // setHistory((prevState) => ({
      //   ...prevState,
      //   ['min_price']: min,
      //   ['max_price']: max,
      // }));
      // setHistory({ ...data, min_price: min, max_price: max });
    }
  };

  const filterListId = (parent) => {
    // setFilterExperiences([]);
    // setData({ categories: parent?.id });
    if (getCategoryids == parent?.id) {
      setCategory("");
    } else {
      setCategory(parent?.id);
    }
    // setData((prevState) => ({
    //   ...prevState,
    //   ['categories']: parent?.id,
    // }));
    setHistory({ categories: parent?.id });
    setHistory((prevState) => ({
      ...prevState,
      ['categories']: parent?.id,
    }));
    setFilterList(parent.child_category);
    setSelectedList([...selectedList, parent]);
  };

  const preListId = (list) => {
    setFilterList(list?.child_category);
    for (let x of selectedList) {
      if (x.id == list.id) {
        let index = selectedList.indexOf(x);
        selectedList.splice(index);
      }
    }
    filterListId(list);
  };

  const listItemsData = (item) => {
    return (
      <div className="p-0">
        {item?.length > 0 &&
          item?.map((myList) => (
            <li
              key={myList?.id}
              onClick={() => {
                filterListId(myList);
              }}
              style={selectedList.length > 0 ? { marginLeft: '22px' } : {}}
            >
              <a href="javascript:void(0)" style={selectedList.length == 0 ? { fontWeight: 'bold' } : {}}>
                {myList?.name}
              </a>
            </li>
          ))}
      </div>
    );
  };

  const preItems = (item) => {
    let lastItem = item[item.length - 1];

    return (
      <div className="p-0">
        {item.length > 0 &&
          item.map((myList) => (
            <li key={myList?.id} onClick={() => preListId(myList)} className={"item" + (myList.id)}>
              <a href="javascript:void(0)">
                {myList.id == lastItem.id && myList?.child_category.length > 0 ? (
                  <FontAwesomeIcon icon={faAngleDown} style={{ paddingRight: '8px' }} />
                ) : myList?.child_category.length > 0 ? (
                  <FontAwesomeIcon icon={faAngleLeft} style={{ paddingRight: '10px' }} />
                ) : myList?.child_category.length == 0 ? (
                  <input
                    type="checkbox"
                    className="form-check-input me-3"
                    checked
                  />
                ) : ''
                }
                {myList.id == lastItem.id ? (<b>{myList?.name}</b>) : myList?.name}
              </a>
            </li>
          ))}
      </div>
    );
  };

  const removeCountryId = (event) => {
    if (event?.length == 0) {
      setCountryId();
    }

    var idArr = [];
    event?.map((item) => {
      let itemId = item?.id;
      idArr.push(itemId);
      let newIds = idArr.toString();
      setCountryId(newIds);
    });
  };

  const removeStateId = (event) => {
    if (event?.length == 0) {
      setStateId();
    }

    var idArr = [];
    event?.map((item) => {
      let itemId = item?.id;
      idArr.push(itemId);
      let newIds = idArr.toString();
      setStateId(newIds);
    });
  };

  const removeCityId = (event) => {
    if (event?.length == 0) {
      setCityId();
    }

    var idArr = [];
    event?.map((item) => {
      let itemId = item?.id;
      idArr.push(itemId);
      let newIds = idArr.toString();
      setCityId(newIds);
    });
  };

  const getCategory = () => {
    {
      getOtherList().then((res) => {
        if (res?.data?.success) {
          setCategory("");
          setOtherListData(res?.data?.data);
          setFilterList(res?.data?.data?.categories);
        }
      });
      setSelectedList([]);
    }
  }
  // useEffect(()=>{
  //   handleFillter()
  // },[])
  const sortingFillter = (e) => {
    let vel = e.target.value;
    // setRating("")
    // setSorting("")
    if (vel.split("_")[1] == "rate") {
      setRating(vel.split("_")[0])
    } else {
      setSorting(vel)
    }
  }
  useEffect(()=>{
    handleFillter()
  },[rating])

  useEffect(()=>{
    handleFillter()
  },[sorting])
  const ratingFillter = (e) => {
    console.log(e.target.value);
  }
  const handlePAgination=(e)=>{
    console.log(e);
    setPageLength(e)
    // handleFillter(e)
  }
  return (
    <>
      <HomeNav />
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-4 col-lg-3 pe-4 filter-col-part mb-4 mb-md-0">
            <div
              className="d-flex align-items-center justify-content-between mb-3 pb-3"
              style={{ borderBottom: "1px rgba(0, 0, 0, 0.1) solid" }}
            >
              <div className="filter_btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                >
                  <path
                    d="M7.29199 11.9583H17.7087V14.0417H7.29199V11.9583ZM4.16699 7.79167H20.8337V9.875H4.16699V7.79167ZM10.417 16.125H14.5837V18.2083H10.417V16.125Z"
                    fill="#454141"
                  />
                </svg>
                <span className="d-inline-block">filter</span>
              </div>
              {/* <div className="clear_all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.9913 5.83333L9.99967 8.825L7.00801 5.83333L5.83301 7.00833L8.82467 9.99999L5.83301 12.9917L7.00801 14.1667L9.99967 11.175L12.9913 14.1667L14.1663 12.9917L11.1747 9.99999L14.1663 7.00833L12.9913 5.83333Z"
                    fill="#454141"
                  />
                </svg>
                <span className="d-inline-block">clear all</span>
              </div> */}
            </div>
            <div className="col-md-8 col-lg-9 ps-md-4">
              <div className="mb-4">
                {/* <div className="home_page_selct_box"> */}
                <div style={{marginBottom:"5px"}}>
                  <p className="mb-0 selct_box_heading">
                    <span className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                      >
                        <path
                          d="M0.829258 0.800572C0.981624 0.648252 1.18825 0.562683 1.4037 0.562683C1.61914 0.562683 1.82577 0.648252 1.97813 0.800572L6 4.82245L10.0219 0.800572C10.1751 0.652569 10.3804 0.570673 10.5934 0.572524C10.8064 0.574375 11.0102 0.659826 11.1608 0.81047C11.3115 0.961114 11.3969 1.1649 11.3988 1.37793C11.4006 1.59097 11.3187 1.79621 11.1707 1.94945L6.57444 6.54577C6.42207 6.69809 6.21545 6.78366 6 6.78366C5.78456 6.78366 5.57793 6.69809 5.42557 6.54577L0.829258 1.94945C0.676938 1.79708 0.59137 1.59046 0.59137 1.37501C0.59137 1.15956 0.676938 0.952939 0.829258 0.800572V0.800572Z"
                          fill="#8F8F8F"
                        />
                      </svg>
                    </span>
                  </p>
                  <Multiselect
                    options={country}
                    displayValue="name"
                    placeholder="Country"
                    onSelect={(e) => {
                      getSelecedId({ e, title: "country" });
                    }}
                    onRemove={(event) => removeCountryId(event)}
                    selectedValues={selectedValue?.countryIds}
                    emptyRecordMsg="No Country Available"
                    selectionLimit='1'
                    hidePlaceholder='false'
                    className="filtter"

                  />
                </div>
                <div  style={{marginBottom:"5px"}}>
                  <p className="mb-0 selct_box_heading">
                    <span className="location_border">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                      >
                        <path
                          d="M0.829258 0.800572C0.981624 0.648252 1.18825 0.562683 1.4037 0.562683C1.61914 0.562683 1.82577 0.648252 1.97813 0.800572L6 4.82245L10.0219 0.800572C10.1751 0.652569 10.3804 0.570673 10.5934 0.572524C10.8064 0.574375 11.0102 0.659826 11.1608 0.81047C11.3115 0.961114 11.3969 1.1649 11.3988 1.37793C11.4006 1.59097 11.3187 1.79621 11.1707 1.94945L6.57444 6.54577C6.42207 6.69809 6.21545 6.78366 6 6.78366C5.78456 6.78366 5.57793 6.69809 5.42557 6.54577L0.829258 1.94945C0.676938 1.79708 0.59137 1.59046 0.59137 1.37501C0.59137 1.15956 0.676938 0.952939 0.829258 0.800572V0.800572Z"
                          fill="#8F8F8F"
                        />
                      </svg>
                    </span>
                  </p>
                  <Multiselect
                    options={state}
                    displayValue="name"
                    placeholder="State"
                    emptyRecordMsg="No State Available"
                    onSelect={(e) => {
                      getSelecedId({ e, title: "state" });
                    }}
                    onRemove={(event) => removeStateId(event)}
                    selectedValues={selectedValue?.stateIds}
                    selectionLimit='1'
                    hidePlaceholder='false'
                    // style={{
                    //   option: {
                    //     textTransform: "capitalize",
                    //     padding: "5px 10px",
                    //     fontSize: "13px",
                    //   },
                    //   searchBox: {
                    //     border: 0,
                    //   },
                    // }}
                    className="filtter"
                  />
                </div>
                <div  style={{marginBottom:"5px"}}>
                  <p className="mb-0 selct_box_heading">
                    <span className="location_border">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                      >
                        <path
                          d="M0.829258 0.800572C0.981624 0.648252 1.18825 0.562683 1.4037 0.562683C1.61914 0.562683 1.82577 0.648252 1.97813 0.800572L6 4.82245L10.0219 0.800572C10.1751 0.652569 10.3804 0.570673 10.5934 0.572524C10.8064 0.574375 11.0102 0.659826 11.1608 0.81047C11.3115 0.961114 11.3969 1.1649 11.3988 1.37793C11.4006 1.59097 11.3187 1.79621 11.1707 1.94945L6.57444 6.54577C6.42207 6.69809 6.21545 6.78366 6 6.78366C5.78456 6.78366 5.57793 6.69809 5.42557 6.54577L0.829258 1.94945C0.676938 1.79708 0.59137 1.59046 0.59137 1.37501C0.59137 1.15956 0.676938 0.952939 0.829258 0.800572V0.800572Z"
                          fill="#8F8F8F"
                        />
                      </svg>
                    </span>
                  </p>
                  <Multiselect
                    options={citys}
                    displayValue="name"
                    placeholder="City"
                    emptyRecordMsg="No City Available"
                    onSelect={(e) => {
                      getSelecedId({ e, title: "city" });
                    }}
                    onRemove={(event) => removeCityId(event)}
                    selectedValues={selectedValue?.cityIds}
                    selectionLimit='1'
                    hidePlaceholder='false'
                    className="filtter"
                  />
                </div>
                {/* //////// */}

                {/* </div> */}
              </div>
            </div>
            {/* ============= */}
            <div
              className="mb-2 pb-3"
              style={{ borderBottom: "1px rgba(0, 0, 0, 0.1) solid" }}
            >
              <button className="mb-2 border-0 bg-light d-block" onClick={() => getCategory()}>Categories</button>

              <div className="categorieList">
                <ul> {preItems(selectedList)} </ul>
                <ul style={{ marginLeft: "8px" }}>
                  {" "}
                  {listItemsData(filterList)}{" "}
                </ul>
              </div>
            </div>
            {/* ============= */}
            <div className="mt-2">
              <div className="mb-3 pt-2">Price (₹)</div>
              <MultiRangeSlider
                min={0}
                max={1000}
                onChange={({ min, max }) => handleChange(min, max)}
              />
            </div>
            {/* <div
              className="price_range_text mb-3 collapsed "
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              price range
              <button className="price_range_plus">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="collapse mb-4" id="collapseExample">
              <div className="price_heading mb-1">
                <span id="amount">₹{priceRange}</span>
              </div>
              <div className="price_paragraph mb-4">
                <span className="me-2"> Average </span>
                <span>
                  <span className="me-1"> price </span>
                  <span>$19,719</span>
                </span>
              </div>
              <RangeSlider
                value={priceRange}
                min={0}
                max={1000}
                onChange={(changeEvent) =>
                  setPriceRange(changeEvent.target.value)
                }
              />
            </div> */}
            <div
              className="price_range_text mb-3 collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample2"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample2"
              style={{ marginTop: "60px" }}
            >
              {`language offered ${!activityStatusFilter?.language?.length > 0
                ? ""
                : `(${activityStatusFilter?.language?.length})`
                }`}

              <button className="price_range_plus">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="collapse" id="collapseExample2">
              {activity.language &&
                activity.language?.length &&
                activity.language?.map((data, index) => (
                  <div className="custom_checkbox" key={index}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="language"
                      id={data?.name}
                      checked={data?.isSelected}
                      onChange={(event) => {
                        onChangeActivity(data.id, event);
                      }}
                    />
                    <label className="form-check-label" htmlFor={data?.name}>
                      {data?.name}
                    </label>
                  </div>
                ))}
            </div>
            <div
              className="price_range_text mb-3 collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample3"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample3"
            >
              {`activity type ${!activityStatusFilter?.activity?.length > 0
                ? ""
                : `(${activityStatusFilter?.activity?.length})`
                }`}
              <button className="price_range_plus">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="collapse" id="collapseExample3">
              {activity.activity &&
                activity.activity?.length &&
                activity.activity?.map((data, index) => (
                  <div className="custom_checkbox" key={index}>
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name="activity"
                      id={data?.name}
                      checked={data?.isSelected}
                      onChange={(event) => onChangeActivity(data.id, event)}
                    />
                    <label className="form-check-label" htmlFor={data?.name}>
                      {data?.name}
                    </label>
                  </div>
                ))}
            </div>
            <div
              className="price_range_text mb-3 collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample4"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample4"
            >
              {`Time Of Day ${!activityStatusFilter?.weekdays?.length > 0
                ? ""
                : `(${activityStatusFilter?.weekdays?.length})`
                }`}
              <button className="price_range_plus">
                <span></span>
                <span></span>
              </button>
            </div>
            <div
              className="position-relative search_button ms-2"
              style={{ cursor: "pointer",lineHeight:"40px" }}
              onClick={() => {
                searchBtn();
              }}
            >
              search
            </div>
            <div className="collapse" id="collapseExample4">
              {activity.weekdays &&
                activity.weekdays?.length &&
                activity.weekdays?.map((data, index) => (
                  <div className="custom_checkbox" key={index}>
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name="weekdays"
                      id={data?.name}
                      checked={data?.isSelected}
                      onChange={(event) => onChangeActivity(data.id, event)}
                    />
                    <label className="form-check-label" htmlFor={data?.name}>
                      {data?.name}
                    </label>
                  </div>
                ))}
            </div>
            {/* <div
              className="price_range_text mb-3 collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample5"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample5"
              >
              Accessibility features
              <button className="price_range_plus">
                <span></span>
                <span></span>
              </button>
            </div> */}
            {/* <div className="collapse" id="collapseExample5"></div> */}
          </div>
          <div className="col-md-8 col-lg-9 ps-md-4">

            <div className="total-result">Show {filterExperiences.length} Results</div>

            <div className="filterBar-select d-flex flex-wrap ps-3">
              <select id="price" onChange={(e) => sortingFillter(e)}>
                <optgroup label="Price">
                  <option value="desc">High to low</option>
                  <option value="asc">Low to high</option>
                </optgroup>
                <optgroup label="Rating">
                  <option value="1_rate">1 star above</option>
                  <option value="2_rate">2 star above</option>
                </optgroup>
              </select>
            </div>
            <div className="SwiperSlider-filter-part">
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  Mousewheel,
                  Keyboard,
                ]}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                spaceBetween={50}
                slidesPerView={6}
                navigation={false}
                allowTouchMove={false}
                // onSlideChange={(swiper) => console.log(swiper)}
                // onSwiper={(swiper) => console.log(swiper)}
                className="SwiperSlider-filter"
              >
                {activityStatusFilter?.language?.length > 0 &&
                  activityStatusFilter?.language?.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <button
                          key={index}
                          type="button"
                          id="filterBtn"
                          className="btn"
                          onClick={(e) => {
                            e.target.name = "language";
                            onChangeActivity(item.id, e);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="me-1"
                          />
                          {item.name}
                        </button>
                      </SwiperSlide>
                    );
                  })}
                {activityStatusFilter?.activity?.length > 0 &&
                  activityStatusFilter?.activity?.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <button
                          key={index}
                          type="button"
                          id="filterBtn"
                          className="btn mb-2"
                          onClick={(e) => {
                            e.target.name = "activity";
                            onChangeActivity(item.id, e);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="me-1"
                          />
                          {item.name}
                        </button>
                      </SwiperSlide>
                    );
                  })}
                {activityStatusFilter?.weekdays?.length > 0 &&
                  activityStatusFilter?.weekdays?.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <button
                          key={index}
                          type="button"
                          id="filterBtn"
                          className="btn mb-2"
                          onClick={(e) => {
                            e.target.name = "weekdays";
                            onChangeActivity(item.id, e);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="me-1"
                          />
                          {item.name}
                        </button>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <div className="SwiperSlider-filter-arrow">
                <button
                  onClick={() => swiperRef.current?.slidePrev()}
                  className="next-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="18"
                    viewBox="0 0 11 18"
                    fill="none"
                  >
                    <path
                      d="M9.6993 16.955C9.92248 16.7206 10.0479 16.4027 10.0479 16.0713C10.0479 15.7398 9.92248 15.4219 9.6993 15.1875L3.80643 9.00001L9.6993 2.81252C9.91615 2.57676 10.0361 2.26101 10.0334 1.93327C10.0307 1.60552 9.90552 1.292 9.68479 1.06025C9.46407 0.828485 9.16548 0.697026 8.85334 0.694178C8.54121 0.69133 8.24049 0.817322 8.01596 1.04502L1.28143 8.11626C1.05825 8.35067 0.932875 8.66856 0.932875 9.00001C0.932875 9.33147 1.05825 9.64935 1.28143 9.88376L8.01596 16.955C8.23921 17.1893 8.54196 17.321 8.85763 17.321C9.1733 17.321 9.47605 17.1893 9.6993 16.955V16.955Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => swiperRef.current?.slideNext()}
                  className="prev-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="18"
                    viewBox="0 0 11 18"
                    fill="none"
                  >
                    <path
                      d="M1.3007 16.955C1.07752 16.7206 0.952148 16.4027 0.952148 16.0713C0.952148 15.7398 1.07752 15.4219 1.3007 15.1875L7.19357 9.00001L1.3007 2.81252C1.08385 2.57676 0.963855 2.26101 0.966568 1.93327C0.96928 1.60552 1.09448 1.292 1.31521 1.06025C1.53593 0.828485 1.83452 0.697026 2.14666 0.694178C2.45879 0.69133 2.75951 0.817322 2.98404 1.04502L9.71857 8.11626C9.94175 8.35067 10.0671 8.66856 10.0671 9.00001C10.0671 9.33147 9.94175 9.64935 9.71857 9.88376L2.98404 16.955C2.76079 17.1893 2.45804 17.321 2.14237 17.321C1.8267 17.321 1.52395 17.1893 1.3007 16.955V16.955Z"
                      fill="#686363"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="row">
              {/* <Dropdown className="p-0 mb-2 filter-slider">
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Price
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>High to low</Dropdown.Item>
                    <Dropdown.Item>Low to high</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="mx-2 mb-2 filter-slider">
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Rating
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {ratingList.map((item, index) => (
                      <Dropdown.Item onClick={() => setrecord(index + 1)}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}

              {filterExperiences.length > 0
                ? filterExperiences.map((item, index) => (
                  <div
                    className="col-sm-6 col-md-12 col-lg-6 col-xl-3 mt-3 mt-md-4 mt-xl-5 experience-col"
                    key={index}
                  >
                    <CardBox
                      data={item}
                      home={true}
                      setData={loading}
                      previousLength={filterExperiencesLength}
                      index_={index}
                    />
                  </div>
                ))
                : [1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                  <div
                    className="col-sm-6 col-md-12 col-lg-6 col-xl-3 mt-3 mt-md-4 mt-xl-5 experience-col"
                    key={index}
                  >
                    <CardBox home={true} setData={loading} skeleton={true} />
                  </div>
                ))}
            </div>
            <RcPagination style={{

marginLeft: "30%",
marginTop: "20px"
            }} onChange={handlePAgination} total={total}/>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Filter;
