import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as User } from "../../assets/elala/svg/user.svg";
import { ReactComponent as Users } from "../../assets/elala/svg/users.svg";
import { ReactComponent as DownArrow } from "../../assets/elala/svg/dropdown.svg";
import Logo from "../../assets/elala/elala.png";
import "./homeNav.css";
import { useEffect } from "react";
import { getUserDetails } from "../../utils/common";
import useOutsideClick from "./OutsideClick";
import { orderData, settings } from "../../utils/_data";
import { Button } from "react-bootstrap";
import { cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const HomeNav = (props) => {
  const [dropDown, setDropDown] = useState(false);
  const [token, setToken] = useState({});
  const [active, setActive] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [setting, setSetting] = useState(false);
  const [myOrderCount, setMyOrderCount] = useState(0);
  let userDetails = JSON.parse(getUserDetails("userDetails"));
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 95);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  const openDropDown = () => {
    setDropDown(!dropDown);
  };

  const Logout = () => {
    setDropDown(false);
    sessionStorage.clear();
    localStorage.clear();
  };
  const closeDropDown = () => {
    setDropDown(false);
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    setDropDown(false);
  });
  const favicon = document.getElementById("favicon");

  favicon.setAttribute("href", setting);
  useEffect(() => {
    settings().then((res) => {
      if (
        res?.data?.success &&
        res?.data?.message === "Setting Data Retrieved Successfully."
      ) {
        setSetting(res?.data?.data?.company_favicon);
      }
    });
  }, []);

  if (localStorage.getItem('token')) {
    useEffect(() => {
      orderData().then((res) => {
        if (
          res?.data?.success &&
          res?.data?.message === "Orders Retrieved Successfully."
        ) {
          setMyOrderCount(res.data.data.total_order)
        }
      });
    }, []);
  }

  const onSearchClick = () => {
    if (props.searchContainerRef.current) {
      if (props.searchContainerRef.current.style.display === "none") {
        props.searchContainerRef.current.style.display = "block";
      } else {
        props.searchContainerRef.current.style.display = "none";
      }
    }
  }

  return (
    <>
      <div className={`header ${sticky ? "sticky" : ""}`}>
        <div className="w-100 d-flex align-items-center" ref={ref}>
          <div className="container">
            <div className="header-content d-flex align-items-center justify-content-between">
              <div className="header_logo_container">
                <Link to="/" className="elala_logo w-100 linkSet">
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <div className="header_menus_container">
                {props.showSearch && <CIcon className="d-block d-md-none" style={{ width: '20px', height: '20px', marginRight: '1rem' }}
                  icon={cilSearch} size="sm" onClick={onSearchClick} />}
                {/* {token && <div
                  id="nav-icon1"
                  className={`mobil_icon_bar  ${active ? "open" : ""}`}
                  onClick={() => {
                    setDropDown(!dropDown);
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>} */}

                {!Object.keys(props.experience || {}).length > 0 ? (
                  <div className="position-relative">
                    <ul className="elala_menu mb-0 d-inline-flex align-items-center pb-0 w-100 justify-content-around">
                      <li className="elala_menu_item px-2 m-0">
                        <Link
                          to={`${token ? "/experience" : "/login"}`}
                          className="a linkSet"
                          style={{
                            textDecoration: "underline ",
                          }}
                        >
                          <Button variant="outline-danger addExpBtn">Add Experience</Button>
                        </Link>
                      </li>
                      <li className="elala_menu_item px-2 m-0">
                        {token ? (
                          <>
                            <span
                              className="btn p-0 dropdown_angle d-flex align-items-center border-0"
                              type="button"
                              onClick={() => setDropDown(!dropDown)}
                            // id="dropdownMenuButton1"
                            // data-bs-toggle="dropdown"
                            // aria-expanded="false"
                            >
                              {userDetails?.profile_pic ? (
                                <img
                                  src={userDetails?.profile_pic}
                                  alt=""
                                  style={{
                                    height: "2.8rem",
                                    width: "2.8rem",
                                    OObjectFit: "center",
                                    objectFit: "center",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <Users />
                              )}

                              <span className="ms-2 header_login_popup d-inline-block">
                                {userDetails?.full_name}
                                <DownArrow />
                              </span>
                            </span>
                          </>
                        ) : (
                          <Link to="/login" className="a linkSet">
                            Login
                          </Link>
                        )}
                      </li>
                    </ul>

                    {dropDown ? (
                      <ul
                        className={`dropdown-menu d-block bookings_dropdown mb-0`}
                        aria-labelledby="dropdownMenuButton1 "
                      >
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/calender"
                            onClick={() => closeDropDown()}
                          >
                            Calender
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/message"
                            onClick={() => closeDropDown()}
                          >
                            Message
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/manage_experience"
                            onClick={() => closeDropDown()}
                          >
                            Manage Experience
                          </Link>
                        </li>
                        <hr className="my-0 " />
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/my_news_feed"
                            onClick={() => closeDropDown()}
                          >
                            My News Feed
                          </Link>
                        </li>
                        {myOrderCount ? <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/myOrder"
                            onClick={() => closeDropDown()}
                          >
                            My Order
                            <span className="red-text-part ms-2">{myOrderCount}</span>
                          </Link>
                        </li> : null}
                        <hr className="my-0 " />
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/mywishlist"
                            onClick={() => closeDropDown()}
                          >
                            My wishlist
                          </Link>
                        </li>
                        <hr className="my-0 " />
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/profile"
                            onClick={() => closeDropDown()}
                          >
                            Profile
                          </Link>
                        </li>
                        <hr className="my-0 " />
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/"
                            onClick={() => Logout()}
                          >
                            Log out
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item linkSet"
                            to="/help"
                            onClick={() => closeDropDown()}
                          >
                            Help
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="stickey-header"></div> */}
    </>
  );
};

export default HomeNav;

// import React, { useRef, useState } from "react";
// import { Link } from "react-router-dom";
// import { ReactComponent as User } from "../../assets/elala/svg/user.svg";
// import { ReactComponent as Users } from "../../assets/elala/svg/users.svg";
// import { ReactComponent as DownArrow } from "../../assets/elala/svg/dropdown.svg";
// import Logo from "../../assets/elala/elala.png";
// import "./homeNav.css";
// import { useEffect } from "react";
// import { getUserDetails } from "../../utils/common";
// import useOutsideClick from "./OutsideClick";

// const HomeNav = () => {
//   const [dropDown, setDropDown] = useState(false);
//   const [token, setToken] = useState({});
//   const [active, setActive] = useState(false);
//   const [sticky, setSticky] = useState(false);

//   let userDetails = JSON.parse(getUserDetails("userDetails"));
//   useEffect(() => {
//     const handleScroll = () => {
//       setSticky(window.scrollY > 95);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   });

//   useEffect(() => {
//     setToken(localStorage.getItem("token"));
//   }, [token]);

//   const openDropDown = () => {
//     setDropDown(!dropDown);
//   };

//   const Logout = () => {
//     setDropDown(false);
//     sessionStorage.clear();
//     localStorage.clear();
//   };
//   const closeDropDown = () => {
//     setDropDown(false);
//   };

//   const ref = useRef();
//   useOutsideClick(ref, () => {
//     setDropDown(false);
//   });

//   return (
//     <>
//       <div className={`header ${sticky ? "sticky" : ""}`}>
//         <div className="bookings_screen" ref={ref}>
//           <div className="container-fluid px-0">
//             <div className="row align-items-center">
//               <div className="col-6 col-md-3 col-lg-2  col-xxl-2 text-start">
//                 <Link to="/login" className="elala_logo w-100 linkSet">
//                   <img src={Logo} alt="" />
//                 </Link>
//               </div>
//               <div className="col-6 col-md-9 col-lg-6 col-xxl-7 text-start">
//                 <div
//                   id="nav-icon1"
//                   className={`mobil_icon_bar  ${active ? "open" : ""}`}
//                   onClick={() => {
//                     setActive(!active);
//                   }}
//                 >
//                   <span></span>
//                   <span></span>
//                   <span></span>
//                 </div>
//                 <ul
//                   className={`elala_menu mobile-sub-menu mb-0 pb-0 ${
//                     active ? "active" : ""
//                   }`}
//                 >
//                   <li className="d-block d-lg-none">
//                     <User />
//                     <span className="ms-2 header_login_popup d-inline-block">
//                       john doe
//                     </span>
//                   </li>
//                   <li>
//                     <Link to="/home" className="active a linkSet">
//                       home
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/news_feed" className="linkSet">
//                       News Feed
//                     </Link>
//                   </li>
//                   <ul className="d-block d-lg-none mb-0 ps-0 pb-0">
//                     <li>
//                       <Link className="dropdown-item ps-0 linkSet" to="/">
//                         Account
//                       </Link>
//                     </li>
//                     <hr className="my-0" />
//                     <li>
//                       <Link
//                         className="dropdown-item ps-0 linkSet"
//                         to="/bookings"
//                       >
//                         Bookings
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item ps-0 linkSet"
//                         to="/calender"
//                       >
//                         Calender
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item ps-0 linkSet"
//                         to="/payment_details"
//                       >
//                         Payment
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item ps-0 linkSet"
//                         to="/chat_screen"
//                       >
//                         Chat
//                       </Link>
//                     </li>
//                     <hr className="my-0 " />
//                     <li>
//                       <Link
//                         className="dropdown-item ps-0 linkSet"
//                         to="/news_feed"
//                       >
//                         My News Feed
//                       </Link>
//                     </li>
//                     <li>
//                       <Link className="dropdown-item ps-0 linkSet" to="/">
//                         My Order
//                       </Link>
//                     </li>
//                     <hr className="my-0 " />
//                     <li>
//                       <Link className="dropdown-item ps-0 linkSet" to="/">
//                         Log out
//                       </Link>
//                     </li>
//                     <li>
//                       <Link className="dropdown-item ps-0 linkSet" to="/">
//                         Help
//                       </Link>
//                     </li>
//                   </ul>
//                 </ul>
//               </div>
//               <div className="col-lg-4 col-xxl-3 text-end login_popup d-none d-lg-flex justify-content-end">
//                 <ul className="elala_menu mobile-sub-menu mb-0 d-inline-flex align-items-center pb-0">
//                   <li className="p-2">
//                     <Link to="/home" className="active a linkSet">
//                       Add Experience
//                     </Link>
//                   </li>
//                   <li className="p-2">
//                     {token ? (
//                       <>
//                         <span
//                           className="btn ps-0 dropdown_angle d-flex align-items-center border-0"
//                           type="button"
//                           onClick={() => openDropDown()}
//                           id="dropdownMenuButton1"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                         >
//                           {userDetails?.profile_pic ? (
//                             <img
//                               src={userDetails?.profile_pic}
//                               alt=""
//                               style={{
//                                 height: "2.8rem",
//                                 width: "2.8rem",
//                                 oObjectFit: "center",
//                                 objectFit: "center",
//                                 borderRadius: "50%",
//                               }}
//                             />
//                           ) : (
//                             <Users />
//                           )}

//                           <span className="ms-2 header_login_popup d-inline-block">
//                             {userDetails?.full_name}
//                             <DownArrow />
//                           </span>
//                         </span>
//                       </>
//                     ) : (
//                       <Link to="/login" className="active a linkSet">
//                         Login / Sign Up
//                       </Link>
//                     )}
//                   </li>
//                 </ul>

//                 {dropDown ? (
//                   <ul
//                     className={`dropdown-menu d-block bookings_dropdown mb-0`}
//                     aria-labelledby="dropdownMenuButton1 "
//                   >
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/calender"
//                         onClick={() => closeDropDown()}
//                       >
//                         Calender
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/message"
//                         onClick={() => closeDropDown()}
//                       >
//                         Message
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/manage_experience"
//                         onClick={() => closeDropDown()}
//                       >
//                         Manage Experience
//                       </Link>
//                     </li>
//                     <hr className="my-0 " />
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/news_feed"
//                         onClick={() => closeDropDown()}
//                       >
//                         My News Feed
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/myOrder"
//                         onClick={() => closeDropDown()}
//                       >
//                         My Order
//                         <span className="red-text-part ms-2">1</span>
//                       </Link>
//                     </li>
//                     <hr className="my-0 " />
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/profile"
//                         onClick={() => closeDropDown()}
//                       >
//                         Profile
//                       </Link>
//                     </li>
//                     <hr className="my-0 " />
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/"
//                         onClick={() => Logout()}
//                       >
//                         Log out
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item linkSet"
//                         to="/"
//                         onClick={() => closeDropDown()}
//                       >
//                         Help
//                       </Link>
//                     </li>
//                   </ul>
//                 ) : (
//                   ""
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="stickey-header"></div>
//     </>
//   );
// };

// export default HomeNav;
