import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import {produce} from "immer";


const middleware_ = (f) => create(devtools(persist(f, { name: "chat-storage" })));
// const middleware_ = (f) => create(devtools(f, { name: "chat-storage" }));

const SupportChatStore = middleware_(
    devtools((set, get) => ({
        disputeData: [],
        curentChatLogs: "",
        chatLogs: [],
        chatLogHasMoreData: false,
        emptyChat: false,
        setChatLogs: (payload, currentChatUser, lastId) => {
            set(produce((draft) => {
                if (payload.length > 0 && lastId) {
                    draft.chatLogs.unshift(...payload);
                } else {
                    draft.chatLogs = payload;
                }
                draft.emptyChat = !payload.length;
                draft.chatLogHasMoreData = payload.length === process.env.REACT_APP_CHAT_LIMIT ? true : false;
                draft.curentChatLogs = currentChatUser;
                
            }),
            false,
            );
        },

        appendChatLogs: (payload) => {
            set(produce((draft) => {
                draft.chatLogs.push(payload);
            }));
        },
    }))
);
export { SupportChatStore };
export default SupportChatStore;